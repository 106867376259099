(function (angular) {
  'use strict';

  try {
    angular.module('risevision.common.config');
  } catch (err) {
    angular.module('risevision.common.config', []);
  }

  angular.module('risevision.common.components.userstate', [
      'ui.router',
      'ui.router.state.events',
      'risevision.common.components.logging',
      'risevision.common.components.loading',
      'risevision.common.components.scrolling-list',
      'risevision.common.config',
      'risevision.common.gapi',
      'risevision.core.util'
    ])

    // Set up our mappings between URLs, templates, and controllers
    .config(['$urlRouterProvider', '$urlMatcherFactoryProvider', '$stateProvider', '$locationProvider',
      function storeRouteConfig($urlRouterProvider, $urlMatcherFactoryProvider, $stateProvider,
        $locationProvider) {

        $locationProvider.html5Mode(true);
        $locationProvider.hashPrefix('/');

        $urlRouterProvider
          .when(function(url) {
            return url.hash && url.hash.match(/.*(jwt_token)=.*/);
          }, ['$state', 'customAuthFactory', 'userAuthFactory',
            function ($state, customAuthFactory, userAuthFactory) {
              console.log('SSO result received');
              var tokens = window.location.hash.split('&');
              var token1 = tokens[0].split('=')[1];
              var token2 = tokens[1].split('=')[1];
              var userInfo = {
                item: token1
              };

              return customAuthFactory._updateToken(token2, userInfo)
              .then(function() {
                  userAuthFactory.setLoginMethod('SSO');
                  return userAuthFactory.authenticate(true);
                })
                .then(function () {
                  window.location.hash = '';
                })
                .catch(function (e) {
                  return $state.go(
                    'common.auth.unauthorized',
                    { authError: e }
                  );
                });
            }
          ])
          .when(function(url) {
            return url.hash && url.hash.match(/.*(error|error_description)=.*/);
          }, ['$state', 'customAuthFactory', 'userAuthFactory',
            function ($state, customAuthFactory, userAuthFactory) {
              console.log('SSO result received');
              var tokens = window.location.hash.split('&');
              var token1 = tokens[0].split('=')[1];
              var token2 = tokens[1].split('=')[1];

              window.location.hash = '';

              return $state.go(
                'common.auth.unauthorized',
                {
                  authError: token1 + ' - ' + token2
                }
              );
            }
          ])
          .when(/\/.*(id_token|access_token)=.*/, ['$window', 'userAuthFactory', 'openidConnect',
            function ($window, userAuthFactory, openidConnect) {
              console.log('Google Auth result received 1');

              var location = $window.location.href;
              location = location.replace('#/', '#');

              openidConnect.signinRedirectCallback(location)
                .then(function (user) {
                  $window.location.hash = '';

                  userAuthFactory.setLoginMethod('Google');
                  return userAuthFactory.authenticate(true);
                })
                .catch(function () {
                  $window.location.hash = '';
                });
            }
          ])
          .when(function(url) {
            return url.hash && url.hash.match(/.*(id_token|access_token)=.*/);
          }, ['$state', 'userAuthFactory', 'openidConnect',
            function ($state, userAuthFactory, openidConnect) {
              console.log('Google Auth result received 2');

              openidConnect.signinRedirectCallback()
                .then(function (user) {
                  userAuthFactory.setLoginMethod('Google');
                  return userAuthFactory.authenticate(true);
                })
                .then(function () {
                  window.location.hash = '';
                })
                .catch(function (e) {

                  return $state.go(
                    'common.auth.unauthorized',
                    {
                      authError: e
                    }
                  );
                });
            }
          ])
          .when(/\/resetpassword\/.+\/.+/, ['$location', '$state',
            function($location, $state) {
              var parts = $location.absUrl().split('/');
              var token = parts[parts.length - 1];
              var user = parts[parts.length - 2];
              $state.go('common.auth.resetpassword', { user: user, token: token });
            }
          ])
          .otherwise('/');

        // https://stackoverflow.com/questions/24420578/handling-trailing-slashes-in-angularui-router
        $urlMatcherFactoryProvider.strictMode(false);

        // Use $stateProvider to configure states.
        $stateProvider.state('common', {
            template: '<div class="app-login" ui-view></div>'
          })

          .state('common.auth', {
            abstract: true,
            templateProvider: ['$templateCache',
              function ($templateCache) {
                return $templateCache.get('partials/components/userstate/auth-common.html');
              }
            ]
          });
      }
    ])

    .run(['$rootScope', '$state', '$stateParams', 'urlStateService',
      'userState',
      function ($rootScope, $state, $stateParams, urlStateService, userState) {
        userState._restoreState();

        $rootScope.$on('$stateChangeStart', function (event, toState,
          toParams, fromState, fromParams) {
          if (toState && (
              toState.name === 'common.auth.unauthorized' ||
              toState.name === 'common.auth.unregistered' ||
              toState.name === 'common.auth.createaccount') && !toParams.state) {

            if (fromParams.state) {
              $state.params.state = fromParams.state;

              event.preventDefault();

              $state.go(toState.name, $state.params);
            }
          }
        });

        $rootScope.$on('risevision.user.authorized', function () {
          var currentState = $state.current.name;

          if (currentState.indexOf('common.auth') !== -1 && currentState !== 'common.auth.unsubscribe') {
            if (currentState === 'common.auth.unregistered' && !userState.isRiseVisionUser())  {
              return;
            }

            urlStateService.redirectToState($stateParams.state);
          }
        });
      }
    ]);

})(angular);
